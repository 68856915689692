import { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import { PAGES_CACHE_TIME } from '@/common/config/cache';
import { type ClientError } from '@/common/services/api/types';
import { namedComponent } from '@/common/utils/async';
import { isNotFound } from '@/common/utils/http/statusResponse/statusResponse';
import { Loader } from '@/components/Loader';
import { HomesPropsType } from '@/modules/home/screens/Home/Home';
import { getPreview } from '@/modules/preview/services/preview';
import { getHomeDesktop, getHomeMobile } from '@/services/home/home';

const HomeScreen = dynamic(
  () => namedComponent(import('@/modules/home/screens/Home/Home'), 'default'),
  {
    loading: () => <Loader />,
    ssr: true,
  },
);

const HomePage = (props: HomesPropsType) => {
  return <HomeScreen {...props} />;
};

export const getStaticProps: GetStaticProps = async ({
  preview,
  previewData,
}) => {
  try {
    if (preview) {
      const content = await getPreview(previewData as string);

      return {
        props: {
          desktop: content,
          mobile: content,
        },
        revalidate: PAGES_CACHE_TIME.HOME,
      };
    }

    const [desktop, mobile] = await Promise.all([
      getHomeDesktop(),
      getHomeMobile(),
    ]);

    return {
      props: {
        desktop,
        mobile,
      },
      revalidate: PAGES_CACHE_TIME.HOME,
    };
  } catch (error) {
    /**
     * The status code of the back-end for a not found product is wrong
     * and must be fixed to returns the status 404.
     *
     * For now, we needs to use this helper here
     */
    if (isNotFound(error as ClientError)) {
      return {
        notFound: true,
      };
    }

    /**
     * Some unknown error happened while fetching product information
     * The request should fail and we should display generic error page
     */
    return Promise.reject(error);
  }
};

HomePage.displayName = 'HomePage';

export default HomePage;
